import React, {useState} from 'react'
import { getSelectYourPreference } from '../../json/data';

const Benefits = () => {
    const { data } = getSelectYourPreference();
    const [isSelected, setSelected] = useState(0);
  
    const toggleDropdown = (id: number) => {
      setSelected(id);
    };
  
    return (
      <>
        <div className="card-body all-card-item d-flex justify-content-center align-items-center">
          <div className="col-12 col-lg-7 d-flex flex-column align-items-center">
            <div className="row w-100 card-items">
              {
                data.map((item) =>
                  <div key={item.id} onClick={() => toggleDropdown(item.id)} className={`card-item ${isSelected == item.id && 'active'} d-flex  `}>
                    <img src={item.img.default} alt="" width={28} height={28} />
                    <p>{item.title}</p>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </>
    );
}

export default Benefits