export const getAgeData = () => ({
    data: [
        {
            id: 1,
            label: '18 - 30',
            value: '18-30'
        },
        {
            id: 1,
            label: '30 - 45',
            value: '30-45'
        },
        {
            id: 1,
            label: '45 - Above',
            value: '45-above'
        }
    ]
});
export const getGender = () => ({
    data: [
        {
            id: 1,
            label: 'Male',
            value: 'male'
        },
        {
            id: 1,
            label: 'Female',
            value: 'Female'
        },
        {
            id: 1,
            label: 'Other',
            value: 'other'
        }
    ]
});


export const getSelectYourPreference  =() => ({
    data: [
        {
            id: 1,
            title: 'Retail Stores',
            img: require('../assets/img/list-icon-svg/retailStores.svg')
        },
        {
            id: 2,
            title: 'Restaurant',
            img: require('../assets/img/list-icon-svg/restaurant.svg')
        },
        {
            id: 3,
            title: 'Entertainment (Movies, OTT, etc.)',
            img: require('../assets/img/list-icon-svg/streaming-tv-app.svg')
        },
        {
            id: 4,
            title: 'Online Retailers',
            img: require('../assets/img/list-icon-svg/shopping-cart.svg')
        },
        {
            id: 5,
            title: 'Travel & Hotels',
            img: require('../assets/img/list-icon-svg/earth 1.svg')
        },
        {
            id: 6,
            title: 'Others (Please Specify)',
            img: require('../assets/img/list-icon-svg/other.svg')
        },
    ]
})

export const getBenefit  =() => ({
    data: [
        {
            id: 1,
            title: 'Gifting',
            img: require('../assets/img/list-icon-svg/gift.png')
        },
        {
            id: 2,
            title: 'Personal Use',
            img: require('../assets/img/list-icon-svg/man.png')
        },
        {
            id: 3,
            title: 'Discount and Deals',
            img: require('../assets/img/list-icon-svg/discount.png')
        },
        {
            id: 4,
            title: 'Special Occasions',
            img: require('../assets/img/list-icon-svg/sale.png')
        },
        {
            id: 5,
            title: 'Others (Please Specify)',
            img: require('../assets/img/list-icon-svg/quote.png')
        },
    ]
})

export const getPaymentMode  =() => ({
    data: [
        {
            id: 1,
            title: 'UPI',
            img: require('../assets/img/wallet-png/upi.png')
        },
        {
            id: 2,
            title: 'Debit / Credit Card',
            img: require('../assets/img/wallet-png/debit-card.png')
        },
        {
            id: 3,
            title: 'Wallet',
            img: require('../assets/img/wallet-png/wallet.png')
        },
        {
            id: 4,
            title: 'Cash',
            img: require('../assets/img/wallet-png/cash.png')
        },
        {
            id: 5,
            title: 'Pay Later',
            img: require('../assets/img/wallet-png/pay-later.png')
        },
    ]
})
export const getHowDidYouGet  =() => ({
    data: [
        {
            id: 1,
            title: 'Instagram',
            img: require('../assets/img/icon-png/instagram.png')
        },
        {
            id: 2,
            title: 'Twitter',
            img: require('../assets/img/icon-png/twitter.png')
        },
        {
            id: 3,
            title: 'Website',
            img: require('../assets/img/icon-png/cozinco.png')
        },
        {
            id: 4,
            title: 'Friends',
            img: require('../assets/img/icon-png/friend.png')
        },
    ]
})