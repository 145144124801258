import React, { useState, useEffect } from 'react';


const DropDown = ({ data, placeholder }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [value, setValue] = useState('');

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const target = event.target;

      // Check if the clicked element is inside the dropdown or the input
      if (!target.closest('.App')) {
        setDropdownOpen(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener('click', handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="form-group custom-dropdown relative-position">
      <div className="inputContainer">
        <input
          type="text"
          className="form-control"
          placeholder={placeholder}
          onClick={() => toggleDropdown()}
          value={value}
          aria-label=".form-select-lg example"
        />
        {isDropdownOpen ? (
          <span className=" inputIcon">
            <i className="fa fa-angle-up"></i>
          </span>
        ) : (
          <span className=" inputIcon">
            <i className="fa fa-angle-down"></i>
          </span>
        )}
      </div>

      {isDropdownOpen && (
        <ul className="dropdown-menu w-100" style={{ position: 'absolute', zIndex: 1000 }}>
          {data.map((item) => (
            <li key={item.id}>
              <button
                onClick={() => {
                  toggleDropdown();
                  setValue(item.label);
                }}
                className="dropdown-item"
                type="button"
              >
                {item.label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropDown;
