import React, { useState } from 'react';
const ServiceAndFeatures = () => {
    const [isDropdownOpen, setDropdownOpen] = useState(true);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };
    return (
        <>
          <div className="card-body personal-information d-flex justify-content-center align-items-center">
                <div className="col-12 col-lg-8 d-flex flex-column align-items-center">
                    <div className="row w-100">
                        <div className="mb-3">
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows={7}></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServiceAndFeatures