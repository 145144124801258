import React, { useState } from 'react';
import backgroundImage from './../assets/img/bg-image.svg';
import PersonalInfo from './components/PersonalInfo';
import HorizontalStepper from './comman/HorizontalStepper';
import ServiceAndFeatures from './components/ServiceAndFeatures';
import SelectYourPreference from './components/SelectYourPreference';
import Benefits from './components/Benefits';
import Five from './components/Five';
import PaymentMode from './components/PaymentMode';
import HowDidYouGet from './components/HowDidYouGet';

const Home = () => {
  const steps = [
    {
      title: 'Personal Information'
    },
    {
      title: 'Provide your Input for the Services & Features '
    },
    {
      title: 'Select Your Preference for Gift Cards'
    },
    {
      title: 'Benefits of Our Website and Mobile App'
    },
    {
      title: 'Your Preference of Brands for Gift Cards'
    },
    {
      title: 'Preferred Payment Mode'
    },
    {
      title: 'How did you get to know about our Brand?'
    },
    {
      title: ''
    }
  ];
  const [currentStep, setCurrentStep] = useState(2);

  const nextStep = () => {
    setCurrentStep((prevStep: number) => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep((prevStep: number) => prevStep - 1);
  };

  const renderStepComponent = () => {
    switch (currentStep) {
      case 0:
        return <PersonalInfo />;
      case 1:
        return <ServiceAndFeatures />;
      case 2: 
        return <SelectYourPreference />
      case 3 :
        return <Benefits />
      case 4 : 
        return <Five />
      case 5 : 
        return <PaymentMode />
      case 6 : 
        return <HowDidYouGet />
      default:
        return null;
    }
  };

  return (
    <div className="home-Screen">
      <div className='bg-image'>
        <img src={backgroundImage} alt="Background" className='h-20' />
      </div>
      <div className='survey-section'>
        <div className="saveit-title-content">
          <h2 className='mb-3'>Saveit.</h2>
          <h3 className='mt-4'>Application Pre-Launch Survey</h3>
          <p className=''>Glad you are here, Fill this survey form to serve you better.</p>
        </div>
        <div className='d-flex justify-content-center m-1'>
          <div className="col-12 col-md-9 col-lg-7 ">
            <div className="card pb-4">
              <div className="card-header"><HorizontalStepper steps={steps} currentStep={currentStep} /></div>
              <h4>{steps[currentStep].title}</h4>
                {renderStepComponent()}
              <div className="button-group d-flex justify-content-center">
              {currentStep > 0 ? 
                <button className="btn  prevActive" onClick={prevStep}>Previous</button>
                :
                <button className="btn  prev">Previous</button>
              }
              {currentStep < steps.length - 1 ? 
                <button className="btn active" onClick={nextStep}>Next</button>
                :
                <button className="btn active" onClick={() => console.log(currentStep)}>Submit</button>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;