import React, { useState } from 'react';
import { getAgeData, getGender } from '../../json/data';
import DropDown from '../comman/DropDown';

const PersonalInfo = () => {
    const ageData = getAgeData();
    const {data} = getGender();
    const [isDropdownOpen, setDropdownOpen] = useState(true);

    const toggleDropdown = () => {
        console.log(getAgeData);
        setDropdownOpen(!isDropdownOpen);
    };
    return (
        <>

            <div className="card-body personal-information d-flex justify-content-center align-items-center">
                <div className="col-12 col-lg-8 d-flex flex-column align-items-center">
                    <div className="row w-100 custom-row">
                        <div className="col-6">
                            <input type="text" className="form-control" placeholder="First name" />
                        </div>
                        <div className="col-6">
                            <input type="text" className="form-control" placeholder="Last name" />
                        </div>
                    </div>
                    <div className="row w-100">
                        <div className="form-group">
                            <input type="email" className="form-control" id="inputEmail4" placeholder='Email Address' />
                        </div>
                        <div className="form-group">
                            <input type="number" className="form-control" placeholder='Contact Number' />
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" id="inputEmail4" placeholder='City' />
                        </div>
                        <DropDown data={ageData.data} placeholder={'Select Age'} />
                        <DropDown data={data} placeholder={'Select Gender'} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PersonalInfo;