import React, { useState } from 'react';
import { Stepper , Step} from 'react-form-stepper';
import { Transition } from 'react-transition-group';

const HorizontalStepper = ({ steps , currentStep }) => {

  return (
    
    <Stepper
      steps={steps}
      activeStep={currentStep}
      connectorStateColors={true}
      styleConfig={{
        activeBgColor: '#fff',
        activeTextColor: '#466BFF',
        inactiveBgColor: '#E9ECEF',
        inactiveTextColor: '#6A7178',
        completedBgColor: '#466BFF',
        completedTextColor: '#fff',
        size: '2em',
      }}
      connectorStyleConfig={{
        activeColor: '#0f6bff',
        completedColor: '#0f6bff',
      }}
      className={'stepper'}
      stepClassName={'stepper__step'}
    />
  );
};

export default HorizontalStepper;
